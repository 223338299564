exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-lead-form-standalone-js": () => import("./../../../src/templates/leadFormStandalone.js" /* webpackChunkName: "component---src-templates-lead-form-standalone-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-payment-receipt-page-js": () => import("./../../../src/templates/paymentReceiptPage.js" /* webpackChunkName: "component---src-templates-payment-receipt-page-js" */),
  "component---src-templates-pillar-page-js": () => import("./../../../src/templates/pillarPage.js" /* webpackChunkName: "component---src-templates-pillar-page-js" */),
  "component---src-templates-ppc-page-js": () => import("./../../../src/templates/ppcPage.js" /* webpackChunkName: "component---src-templates-ppc-page-js" */),
  "component---src-templates-promo-page-js": () => import("./../../../src/templates/promoPage.js" /* webpackChunkName: "component---src-templates-promo-page-js" */),
  "component---src-templates-resource-index-page-js": () => import("./../../../src/templates/resourceIndexPage.js" /* webpackChunkName: "component---src-templates-resource-index-page-js" */),
  "component---src-templates-tutoring-choices-page-js": () => import("./../../../src/templates/tutoringChoicesPage.js" /* webpackChunkName: "component---src-templates-tutoring-choices-page-js" */)
}

